<template>
  <div id="PlanYourExperience">
    Plan Your Experience
  </div>
</template>

<script>
export default {
  name: "PlanYourExperience",
  mounted() {
    window.scrollTo(0,0)
  }
}
</script>

<style lang="scss" scoped>

</style>